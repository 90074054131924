import React from "react"
import "../../../css/Common.css"
import "./Header.css"
import { getImageURL } from "../../../helpers"

function Header() {
    const logo = getImageURL(process.env.REACT_APP_LOGOS, "header")

    return (
        <div className="header__wrapper">
            <a href={process.env.REACT_APP_COMPANY_URL} rel="noopener noreferrer" target="_blank">
                <img src={logo} alt="logo" className="logo__image" />
            </a>
        </div>
    )
}

export default Header
