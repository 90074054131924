import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setRefreshTokenExpired, logOut } from "../../modules/landing/authSlice"
import { removeItem } from "../../helpers"

const useLogoutModal = () => {
    // const navigate = useNavigate()
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)
    const [countdown, setCountdown] = useState(3)
    const { refreshTokenExpired } = useSelector(store => store.auth)

    const onClose = () => null

    const signInHandler = () => {
        dispatch(logOut())
        localStorage.removeItem("isUserLoggedIn")
        localStorage.removeItem("userInfo")
        localStorage.removeItem("userRoles")
        localStorage.removeItem("countryId")
        // navigate("/auth")
        setOpen(false)
    }

    useEffect(() => {
        let timer
        if (refreshTokenExpired) {
            setOpen(true)
            setCountdown(3)
            timer = setInterval(() => {
                setCountdown(prevCount => {
                    if (prevCount === 1) {
                        clearInterval(timer)
                        dispatch(setRefreshTokenExpired(false))
                        dispatch(logOut())
                        removeItem("isUserLoggedIn")
                        removeItem("userInfo")
                        removeItem("userRoles")
                    }
                    return prevCount > 1 ? prevCount - 1 : prevCount
                })
            }, 1000)
        } else {
            setOpen(false)
        }

        return () => clearInterval(timer)
    }, [refreshTokenExpired])

    return {
        states: { open, countdown },
        functions: { onClose, signInHandler },
    }
}

export default useLogoutModal
