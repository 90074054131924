import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setAlertLoading } from "./toasterSlice"
import Snackbar from "@mui/material/Snackbar"
import SnackbarContent from "@mui/material/SnackbarContent"
import "./Toaster.css"
import success from "../../assets/images/completed_billing.svg"
import error from "../../assets/images/failed_billing.svg"

const toastTypes = {
    success: {
        icon: success,
        class: "toast_message_success",
    },
    error: {
        icon: error,
        class: "toast_message_error",
    },
}

const Toaster = props => {
    const { vertical, horizontal, autoHideDuration } = props
    const [isOpen, setIsOpen] = useState(false)
    const { alert_type, alert_msg, alert_loading } = useSelector(store => store.toast)
    const dispatch = useDispatch()

    useEffect(() => {
        if (alert_loading === true) {
            setIsOpen(true)
            setTimeout(() => {
                dispatch(setAlertLoading(false))
            }, autoHideDuration)
        } else {
            setIsOpen(false)
        }
    }, [alert_loading])

    const handleAlertType = () => {
        if (alert_type || alert_msg) {
            return (
                <SnackbarContent
                    className={`toast_message_container ${toastTypes?.[alert_type]?.class}`}
                    message={
                        <div className={`flex align_center`}>
                            <img src={toastTypes?.[alert_type]?.icon} alt="Snackbar image" width={20} height={20} />
                            <p className="margin-l-10 toast_message">{alert_msg}</p>
                        </div>
                    }
                />
            )
        } else {
            return <></>
        }
    }

    return (
        <Snackbar anchorOrigin={{ vertical, horizontal }} open={isOpen} key={vertical + horizontal}>
            {handleAlertType()}
        </Snackbar>
    )
}

export default Toaster
