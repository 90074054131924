import { createSlice } from "@reduxjs/toolkit"

export const toastSlice = createSlice({
    name: "toast",
    initialState: { alert_type: "", alert_msg: "", alert_loading: null },
    reducers: {
        setAlertLoading: (state, { payload: { alert_loading } }) => {
            state.alert_loading = alert_loading
        },
        setAlertFunc: (state, { payload: { message, type } }) => {
            state.alert_loading = true
            state.alert_msg = message
            state.alert_type = type
        },
    },
})

export const { setAlertLoading, setAlertFunc } = toastSlice.actions

export default toastSlice.reducer
