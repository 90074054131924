import { createSlice } from "@reduxjs/toolkit"

export const initialChargerFilterData = {
    modalFilter: {
        open: {
            emsp: false,
            cpo: false,
            station: false,
            connector: false,
            chargerConnectivityStatus: false,
            connectorStatus: false,
            chargerStatus: false,
            visibilityStatus: false,
            state: false,
            city: false,
            oem: false,
            accessType: false,
            chargerType: false,
            autoCharge: false,
            chargerPowerRating: false,
        },
        values: {
            emsp: null,
            cpo: null,
            station: null,
            connector: [],
            chargerConnectivityStatus: "",
            connectorStatus: "",
            chargerStatus: "",
            chargerSubStatus: "",
            visibilityStatus: "",
            state: "",
            city: null,
            oem: "",
            accessType: "",
            chargerType: "",
            autoCharge: "",
            chargerPowerRatingType: "",
            chargerPowerRatingValue: null,
        },
    },
    quickFilter: {
        country: "",
        charger_name: "",
        cpo: "",
        emsp: "",
        client: "",
        cinNumber: "",
        source: "",
    },
}

const chargerSlice = createSlice({
    name: "charger",
    initialState: {
        showColumns: [],
        activeFilters: initialChargerFilterData,
        platformPrice: "",
        addChargerFormData: null,
        chargerId: "",
        previouslySelectedCompanyIds: [],
        mappedEmspIds: [],
        selectedCpoList: [],
        selectedEmspList: [],
        chargerFormStep: null,
        chargerFormErrors: false,
        companyMappingErrors: false,
        companyMappingChildErrors: [],
        accessTypeErrors: {
            isError: false,
            message: null,
        },
        companyMappingArr: [],
        prevMappedCompany: null,
        cpoList: [],
        emspList: [],
        searchString: "",
        isParentChecked: false,
        triggerSearch: null,
        isCpoSelected: false,
        accessType: "",
        errorMessage: "",
        isEditPowerRatingSuccess: false,
        unmappedIds: [],
        search: "",
        requiredFieldError: false,
        mauRangeError: false,
        bulkChargerPayloadData: {},
        selectedTab: "",
    },
    reducers: {
        setShowColumns: (state, { payload: { columns } }) => {
            state.showColumns = columns
        },
        setActiveFilters: (state, { payload }) => {
            state.activeFilters = payload
        },
        setPlatformPrice: (state, { payload: { price } }) => {
            state.platformPrice = price
        },
        setChargerFormData: (state, { payload: data }) => {
            state.addChargerFormData = data
        },
        setChargerId: (state, { payload: { chargerId } }) => {
            state.chargerId = chargerId
        },
        setPreviouslySelectedCompanyIds: (state, { payload }) => {
            state.previouslySelectedCompanyIds = payload
        },
        setMappedEmspIds: (state, { payload }) => {
            state.mappedEmspIds = payload
        },
        resetChargerFormData: state => {
            state.platformPrice = ""
            state.chargerId = ""
            state.previouslySelectedCompanyIds = []
        },
        setSelectedCpoList: (state, { payload: data }) => {
            state.selectedCpoList = data
        },
        setSelectedEmspList: (state, { payload: data }) => {
            state.selectedEmspList = data
        },
        setChargerFormStep: (state, { payload: data }) => {
            state.chargerFormStep = data
        },
        setChargerFormErrors: (state, { payload: data }) => {
            state.chargerFormErrors = data
        },
        setCompanyMappingErrors: (state, { payload: data }) => {
            state.companyMappingErrors = data
        },
        setCompanyMappingChildErrors: (state, { payload: { isError, childId } }) => {
            if (isError) {
                state.companyMappingChildErrors.push(childId)
            } else {
                state.companyMappingChildErrors = state.companyMappingChildErrors?.filter(id => id !== childId)
            }
        },
        resetChargerFormStates: state => {
            return {
                ...state,
                chargerId: "",
                chargerFormErrors: false,
                companyMappingErrors: false,
                companyMappingChildErrors: [],
                previouslySelectedCompanyIds: [],
                selectedCpoList: [],
                selectedEmspList: [],
                chargerFormStep: null,
                cpoList: [],
                emspList: [],
                prevMappedCompany: null,
                isParentChecked: false,
                searchString: "",
                isCpoSelected: false,
                triggerSearch: null,
                companyMappingArr: [],
                errorMessage: "",
                accessType: "",
                accessTypeErrors: {
                    isError: false,
                    message: null,
                },
                mappedEmspIds: [],
                mauRangeError: false,
                requiredFieldError: false,
            }
        },
        setPrevMappedCompanyArr: (state, { payload: data }) => {
            state.prevMappedCompany = data
        },
        setCpoList: (state, { payload: data }) => {
            state.cpoList = data
        },
        setEmspList: (state, { payload: data }) => {
            state.emspList = data
        },
        setSearchMapping: (state, { payload: data }) => {
            state.searchString = data
        },
        setIsParentChecked: (state, { payload: data }) => {
            state.isParentChecked = data
        },
        setTriggerSearch: (state, { payload: data }) => {
            state.triggerSearch = Boolean(data)
        },
        setIsCpoSelected: (state, { payload: data }) => {
            state.isCpoSelected = data
        },
        setAccessType: (state, { payload: data }) => {
            state.accessType = data
        },
        setErrorMessage: (state, { payload: data }) => {
            state.errorMessage = data
        },
        setAccessTypeErrors: (state, { payload: { isError, message } }) => {
            state.accessTypeErrors.isError = isError
            state.accessTypeErrors.message = message
        },
        setIsEditPowerRatingSuccess: (state, { payload: data }) => {
            state.isEditPowerRatingSuccess = data
        },
        setUnmappedId: (state, { payload: { id, type } }) => {
            if (type === "add") {
                state.unmappedIds.push(id)
            }
            if (type === "remove") {
                if (state.unmappedIds?.includes(id)) state.unmappedIds = state.unmappedIds?.filter(i => i !== id)
            }
            if (type === "add_all") {
                state.unmappedIds = [...state.unmappedIds, ...id]
            }
            if (type === "remove_all") {
                state.unmappedIds = state.unmappedIds.filter(item => !id.includes(item))
            }
        },
        setSearch: (state, { payload: { search } }) => {
            state.search = search
        },
        setRequiredFieldError: (state, { payload }) => {
            state.requiredFieldError = payload
        },
        setMauRangeError: (state, { payload }) => {
            state.mauRangeError = payload
        },
        setBulkChargerPayloadData: (state, { payload }) => {
            state.bulkChargerPayloadData = payload
        },
        setSelectedTab: (state, { payload }) => {
            state.selectedTab = payload
        },
    },
})

export const {
    setShowColumns,
    setActiveFilters,
    setPlatformPrice,
    resetChargerFormData,
    setChargerId,
    setPreviouslySelectedCompanyIds,
    setMappedEmspIds,
    setChargerFormData,
    setSelectedCpoList,
    setSelectedEmspList,
    setChargerFormStep,
    setChargerFormErrors,
    setCompanyMappingErrors,
    setPrevMappedCompanyArr,
    resetChargerFormStates,
    setCpoList,
    setEmspList,
    setSearchMapping,
    setIsParentChecked,
    setTriggerSearch,
    setIsCpoSelected,
    setCompanyMappingChildErrors,
    setAccessType,
    setErrorMessage,
    setAccessTypeErrors,
    setIsEditPowerRatingSuccess,
    setUnmappedId,
    setSearch,
    setRequiredFieldError,
    setMauRangeError,
    setBulkChargerPayloadData,
    setSelectedTab,
} = chargerSlice.actions
export default chargerSlice.reducer
