import React from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import box__graphic from "../../assets/images/login__graphic.svg"
import Header from "./common/Header"
import "../../css/Common.css"

const LandingLayout = props => {
    const { isLoggedIn } = useSelector(store => store.auth)
    return isLoggedIn ? (
        <Navigate to="/" replace />
    ) : (
        <div className="full-container">
            <Header />
            <div className="box__wrapper">
                <div className="box__grid ">
                    <div className="box__text center">
                        <p className="large-title">{`Welcome To ${process.env?.REACT_APP_COMPANY}`}</p>
                        <p className="title padding-r-10 padding-l-10" style={{ padding: 20 }}>
                            {`A Comprehensive dashboard to monitor and control all your ${process.env?.REACT_APP_COMPANY} EV solutions. Monitor
                            transactions and charging in real-time and stay updated with all aspects of operations.`}
                        </p>
                    </div>
                    <img src={box__graphic} alt="box" className="login_graphic" />
                </div>
                <div className="box__grid">{props.children}</div>
            </div>
            <div className="box__footer sub-title">© Sharify services private limited All rights reserved</div>
        </div>
    )
}

export default LandingLayout
