import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import "./font/MarkPro.ttf"
import reportWebVitals from "./reportWebVitals"
import Application from "./modules/Application"
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import { Provider } from "react-redux"
import store from "./stores/TempStore"
console.disableYellowBox = true

const theme = createTheme({
    typography: {
        fontFamily: "MarkPro,sans-serif",
    },
    palette: {
        primary: {
            main: "#101941",
        },
        secondary: {
            main: "#FC441E",
        },
    },

    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...((ownerState.variant === "contained" || ownerState.variant === "primaryContained") && {
                        background: "linear-gradient(180deg, #FC441E 0%, #FE5567 100%)",
                        color: "#fff",
                    }),
                    text: {
                        textTransform: "none",
                    },
                    "&.Mui-disabled": {
                        color: "#ffffff",
                        opacity: "0.6",
                    },
                }),
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "6px",
                    background: "#F5F6F7",
                    position: "relative",
                    border: "none",
                    height: "43px",
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                    },
                    "&.Mui-disabled": {
                        color: "#101941",
                    },
                    "&:focus .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#101941",
                        borderWidth: 2,
                    },
                    "&:hover:not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#101941",
                    },
                },

                input: {
                    padding: "12px",
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "grey",
                    textTransform: "none",
                    fontSize: 12,
                    top: -2,
                    "&.Mui-disabled": {
                        color: "#101941",
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: "100%",
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: "#101941",
                    fontSize: 14,
                    marginBottom: 8,
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    fontSize: 12,
                    color: "#101941",
                },
            },
        },
    },
})

ReactDOM.createRoot(document.getElementById("root")).render(
    // <React.StrictMode>
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <Application />
            </Provider>
        </ThemeProvider>
    </StyledEngineProvider>
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
