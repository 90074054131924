import { isRejectedWithValue } from "@reduxjs/toolkit"
import { setAlertFunc } from "../components/Toaster/toasterSlice"
import { removeItem } from "../helpers"

export const rtkQueryErrorLogger =
    ({ dispatch }) =>
    next =>
    action => {
        if (isRejectedWithValue(action)) {
            console.log("We got a rejected action!", action.payload)
            //status 403 when refresh token expire
            if (action?.meta?.baseQueryMeta?.response?.status === 403) {
                removeItem("isUserLoggedIn")
                removeItem("userInfo")
                removeItem("userRoles")
                window.location.href = "/"
            }

            if (action.payload.status === 403) {
                dispatch(
                    setAlertFunc({
                        type: "error",
                        message: action?.payload?.error
                            ? action.payload.error
                            : action?.payload?.data?.message
                            ? action.payload.data.message
                            : action.payload.data.detail,
                    })
                )
                setTimeout(() => {
                    window.location.href = "/auth"
                }, 100)
            }
            if (action.payload.status === 404 || action.payload.status === 500 || action.payload.status === 400) {
                dispatch(
                    setAlertFunc({
                        type: "error",
                        message: action?.payload?.error
                            ? action.payload.error
                            : action?.payload?.data?.message
                            ? action.payload.data.message
                            : action?.payload?.data?.meta?.message,
                    })
                )
            }

            if (action.payload?.originalStatus === 400 || action.payload?.originalStatus === 500) {
                dispatch(
                    setAlertFunc({
                        type: "error",
                        message: "Something went wrong!",
                    })
                )
            }

            if (action.payload.status === "FETCH_ERROR") {
                dispatch(
                    setAlertFunc({
                        type: "error",
                        message: "Network Issue!",
                    })
                )
            }
        }
        return next(action)
    }
