import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { mainSplitApi } from "../api"
import auth from "../modules/landing/authSlice"
import charger from "../modules/cms/charger-management/Features/Chargers/AllChargers/chargerSlice"
import chargingSessions from "../modules/cms/billing/Features/ChargingSession/chargingSessionsSlice"
import payouts from "../modules/cms/billing/Features/CompanyPayouts/payoutsSlice"
import complaint from "../modules/cms/complaints/Features/Complaints/complaintSlice"
import toast from "../components/Toaster/toasterSlice"
import loadmanagement from "../modules/cms/charger-management/Features/LoadManagement/loadManagementSlice"
import chargerPnl from "../modules/cms/billing/Slice/chargerPnlSlice"
import autoTrigger from "../modules/cms/auto-trigger/Slice/AutoTriggersSlice"
import station from "../modules/cms/charger-management/Features/Stations/stationSlice"

import { rtkQueryErrorLogger } from "./ErrorLogger"

const combinedReducer = combineReducers({
    [mainSplitApi.reducerPath]: mainSplitApi.reducer,
    auth,
    charger,
    chargingSessions,
    complaint,
    toast,
    payouts,
    loadmanagement,
    chargerPnl,
    autoTrigger,
    station,
})

const rootReducer = (state, action) => {
    if (action.type === "auth/logOut") {
        state = undefined
    }
    return combinedReducer(state, action)
}

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(mainSplitApi.middleware).concat(rtkQueryErrorLogger),
})

export default store
