import { createSlice } from "@reduxjs/toolkit";
import { initialRangeValue } from "../../../../helpers";


export const initialFilterData = {
    quickFilters: {
        event_obj : {EventId:'' , EventLinkedID : ""},
        event_id: "",
        event_linked_id: "",
        alert_id: "",
        event_status: "",
        priority: "",
        date_range: initialRangeValue,
        start_date: "",
        end_date: "",
    },
    page :1,
    page_size : 25 
}


const autoTriggerSlice = createSlice({
    name : 'autoTrigger',
    initialState : {
        activeTriggers: [],
        initialFilters : initialFilterData
    },
    reducers :{
        setActiveTriggers : (state ,{payload}) =>{
            state.activeTriggers = payload
        },

        setFilters : (state , {payload}) =>{
            state.initialFilters = payload
        }
      
    }
})

export const {setActiveTriggers , setFilters} = autoTriggerSlice.actions
export default autoTriggerSlice.reducer