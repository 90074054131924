import { createSlice } from "@reduxjs/toolkit"

const initialQuickFilterData = {
    country_id: 101,
    hub_name: "",
    state_id: "",
    city_id: "",
    access_type: "",
}

const loadManagementSlice = createSlice({
    name: "loadmanagement",
    initialState: {
        activeFilterReduxState: initialQuickFilterData,
    },
    reducers: {
        setActiveFilterReduxState: (state, { payload }) => {
            state.activeFilterReduxState = payload
        },
    },
})

export const { setActiveFilterReduxState } = loadManagementSlice.actions
export default loadManagementSlice.reducer
