import { useEffect } from "react"
import { Navigate, useNavigate, useLocation } from "react-router-dom"
import Header from "../../components/Header"
import { getItem } from "../../helpers"
import { useSelector } from "react-redux"

const CmsLayout = props => {
    const navigate = useNavigate()
    const location = useLocation()

    const isLoggedIn = getItem("isUserLoggedIn")

    const { userInfo, userRoles } = useSelector(store => store.auth)

    useEffect(() => {
        const dashboardHiddenCompanyIds = process.env.REACT_APP_DASHBOARD_HIDDEN_COMPANIES || []
        if (
            dashboardHiddenCompanyIds?.length &&
            dashboardHiddenCompanyIds.includes(userInfo?.companyId) &&
            location.pathname === "/"
        ) {
            navigate("/complaints/all-complaints", { replace: true })
        }
    }, [location])

    return isLoggedIn ? (
        <>
            <Header {...{ userInfo, userRoles }} />
            {props.children}
        </>
    ) : (
        <Navigate to="/auth" replace />
    )
}

export default CmsLayout
