import { createSlice } from "@reduxjs/toolkit"

const initialQuickFilterData = {
    quickFilter: {
        company: "",
        status: "",
        month: "",
    },
    hostInvQuickFilter: {
        status: "",
        month: "",
    },
}

const payoutsSlice = createSlice({
    name: "payouts",
    initialState: {
        showColumns: [],
        companyActiveFilters: initialQuickFilterData?.quickFilter,
        cpoPlatformQuickActiveFilers: initialQuickFilterData?.quickFilter,
        hostInvQuickActiveFilers: initialQuickFilterData?.hostInvQuickFilter,
    },
    reducers: {
        setCompanyActiveFilters: (state, { payload }) => {
            state.companyActiveFilters = payload
        },
        setShowColumns: (state, { payload: { columns } }) => {
            state.showColumns = columns
        },
        setCpoPlatformQuickActiveFilers: (state, { payload }) => {
            state.cpoPlatformQuickActiveFilers = payload
        },
        setHostInvQuickActiveFilers: (state, { payload }) => {
            state.hostInvQuickActiveFilers = payload
        },
    },
})

export const { setShowColumns, setCompanyActiveFilters, setCpoPlatformQuickActiveFilers, setHostInvQuickActiveFilers } =
    payoutsSlice.actions
export default payoutsSlice.reducer
