import { createSlice } from "@reduxjs/toolkit"

const stationSlice = createSlice({
    name: "station",
    initialState: {
        showColumns: [],
    },
    reducers: {
        setShowColumns: (state, { payload: { columns } }) => {
            state.showColumns = columns
        },
    },
})
export const { setShowColumns } = stationSlice.actions
export default stationSlice.reducer
