import React from "react"
import RiseLoader from "react-spinners/RiseLoader"

function AppLoader(props) {
    return (
        <div style={{width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <RiseLoader loading={true} color="#101941" size={20} margin={2} />
        </div>
    )
}

export default AppLoader
