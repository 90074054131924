import { createSlice } from "@reduxjs/toolkit"

const complaintSlice = createSlice({
    name: "complaint",
    initialState: {
        showColumns: [],
    },
    reducers: {
        setShowColumns: (state, { payload: { columns } }) => {
            state.showColumns = columns
        },
    },
})
export const { setShowColumns } = complaintSlice.actions
export default complaintSlice.reducer
